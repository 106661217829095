import HttpService from "../HttpService";
import SporteventCompetition from "@/models/sportevent/SporteventCompetition";
import BaseService from "../BaseService";

class SporteventCompetitionService extends BaseService<SporteventCompetition> {
  public url: string;
  constructor(url = "sportevent/sporteventcompetition/") {
    super(url, "localstore_sporteventcompetitions");
    this.url = url;
  }

  async getByAgeGender(sporteventId: string, age: number, gender: string) {
    const response = await HttpService.get<SporteventCompetition[]>(
      `${this.url}byage/${sporteventId}/${age}/${gender}/`
    );
    return response;
  }

  async getBySportevent(sporteventId: string) {
    const response = await HttpService.get<SporteventCompetition[]>(
      `${this.url}bysportevent/${sporteventId}/`
    );
    return response;
  }
  async getAllSporteventCompetitionsDataFromId(
    sporteventCompetitionIds: (string | undefined)[]
  ): Promise<SporteventCompetition[]> {
    const allSporteventCompetition: SporteventCompetition[] = [];
    const items = await this.getAll();
    if(items.length === 0){      
      await this.getAll(true);
    }
    sporteventCompetitionIds.forEach((id) => {
      if (id !== undefined)
        this.get(id).then((sc: SporteventCompetition) => {
          sc.disciplines.sort((a, b) => (a.name > b.name ? 1 : -1));
          allSporteventCompetition.push(sc);
        });
    });

    return allSporteventCompetition;
  }
}
export default new SporteventCompetitionService();
