import Person from "./Person";

export default class Society {
  id?: string;
  shortname = "";
  name = "";
  members?: Person[];
  oetbWienID?: number;
  oetbID?: number;
  constructor(society?: Society) {
    if (society === undefined || society === null) {
      return;
    }
    this.id = society.id;
    this.shortname = society.shortname;
    this.name = society.name;
    this.members = society.members;
    this.oetbWienID = society.oetbWienID;
    this.oetbID = society.oetbID;
  }
}
