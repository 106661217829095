import Sportevent from "./Sportevent";
import SporteventCompetition from "./SporteventCompetition";
import Person from "../societymgmt/Person";
import Society from "../societymgmt/Society";

export default class Starter {
  id?: string;
  person: Person = new Person();
  startsForSociety: Society = new Society();
  sportevent: Sportevent = new Sportevent();
  sporteventCompetition: SporteventCompetition = new SporteventCompetition();
  positionInStartgroup = 99999;
  present = true;

  constructor(starter?: Starter) {
    if (starter === undefined || starter === null) {
      return;
    }
    this.id = starter.id;
    this.person = starter.person;
    this.startsForSociety = starter.startsForSociety;
    this.sportevent = starter.sportevent;
    this.sporteventCompetition = starter.sporteventCompetition;
    this.positionInStartgroup = starter.positionInStartgroup;
    this.present = starter.present;
  }

  NameInclude(filterString: string): boolean {
    const start_search_text1 =
      this.person.firstname + " " + this.person.lastname;
    const start_search_text2 =
      this.person.lastname + " " + this.person.firstname;

    if (
      start_search_text1.toLowerCase().includes(filterString) ||
      start_search_text2.toLowerCase().includes(filterString)
    ) {
      return true;
    }
    return false;
  }
}
