export default class AgeGenderGroup {
  id?: string;
  gender = "F";
  ageFrom = 0;
  ageTo = 0;

  constructor(ageGenderGroup?: AgeGenderGroup) {
    if (ageGenderGroup === undefined || ageGenderGroup === null) {
      return;
    }
    this.id = ageGenderGroup.id;
    this.gender = ageGenderGroup.gender;
    this.ageFrom = ageGenderGroup.ageFrom;
    this.ageTo = ageGenderGroup.ageTo;
  }

  public getName(): string {
    return `${this.gender} ${this.ageFrom}-${this.ageTo}`;
  }
}
