import Society from "../societymgmt/Society";
import Sportevent from "./Sportevent";
import Starter from "./Starter";
import TeamCompetition from "./TeamCompetition";

export default class Team {
  id?: string | null;
  sportevent: Sportevent = new Sportevent();
  society: Society = new Society();
  name = "";
  starters: Starter[] = [];
  points = 0;
  teamCompetition = new TeamCompetition();
  rank = 0;
  oetbWienID = 0;

  constructor(team?: Team) {
    if (team === undefined || team === null) {
      return;
    }
    this.id = team.id;
    this.sportevent = team.sportevent;
    this.name = team.name;
    this.society = team.society;
    this.starters = team.starters;
    this.points = team.points;
    this.teamCompetition = team.teamCompetition;
    this.oetbWienID = team.oetbWienID;
  }
}
