import HttpService from "../HttpService";
import Starter from "@/models/sportevent/Starter";
import store from "../../store/index";
import localstore_sportevent from "../../store/localstore_sportevent.module";
import localstore_starters from "../../store/localstore_starters.module";
import SporteventCompetition from "@/models/sportevent/SporteventCompetition";
import SporteventCompetitionService from "./SporteventCompetitionService";
import BaseService from "../BaseService";

class StarterService extends BaseService<Starter> {
  public url: string;

  constructor(url = "sportevent/starter/") {
    super(url, "localstore_starters");
    this.url = url;
  }

  async getAll(
    reload = false,
    onlyActiveCompetition = false
  ): Promise<Starter[]> {
    if (
      (localstore_sportevent.state.sportevent !== null &&
        localstore_sportevent.state.sportevent.id !== null) ||
      reload
    ) {
      const starters = await this.getAllBySporteventWithApi(
        localstore_sportevent.state.sportevent.id
      );
      await store.dispatch("localstore_starters/setAll", starters);
    }
    let starters: Starter[] = localstore_starters.state.entries;
    if (starters.length === 0 && reload == false) {
      starters = await this.getAll(true);
      return this.filterOnlyActiveCompetition(starters, onlyActiveCompetition);
    }
    if (starters !== undefined) {
      starters.forEach(async (s: Starter) => {
        if (s.sporteventCompetition.id !== undefined)
          s.sporteventCompetition = await SporteventCompetitionService.get(
            s.sporteventCompetition.id
          );
      });
      starters = starters.map((s: Starter) => new Starter(s));
      return this.filterOnlyActiveCompetition(starters, onlyActiveCompetition);
    }
    return [];
  }

  filterOnlyActiveCompetition(
    starters: Starter[],
    dofilter = false
  ): Starter[] {
    if (dofilter) {
      const notActiveCompetitions = [
        "KR",
        "KR Ball",
        "KR Fun",
        "KR LA",
        "KR LA Nachmittag",
        "KR LA Vormittag",
        "KR Rede",
        "KR Schwimmen",
        "KR Singen",
        "KR Tanzen",
        "KR Turnen",
        "KR Turnen Nachmittag",
        "KR Turnen Samstag",
        "KR Turnen Sonntag",
        "KR Turnen Vormittag",
        "Arbeitseinsatz",
      ];

      starters = starters.filter(
        (s: Starter) =>
          !notActiveCompetitions.includes(
            s.sporteventCompetition.competition.name
          )
      );
    }
    return starters;
  }

  async getAllBySporteventCompetition(
    sporteventCompetitionId: string
  ): Promise<Starter[]> {
    let starters = await this.getAll();
    starters = starters.filter(
      (s) => s.sporteventCompetition.id === sporteventCompetitionId
    );
    return starters;
  }

  async getAllSporteventCompetitions(): Promise<SporteventCompetition[]> {
    const starters = await this.getAll();

    let allSporteventCompetition: SporteventCompetition[] = [];
    if (starters !== undefined) {
      const sporteventCompetitionIds = [
        ...new Set(starters.map((s) => s.sporteventCompetition.id)),
      ];
      allSporteventCompetition =
        await SporteventCompetitionService.getAllSporteventCompetitionsDataFromId(
          sporteventCompetitionIds
        );
    }
    return allSporteventCompetition;
  }

  async getAllBySporteventWithApi(sporteventId: string): Promise<Starter[]> {
    const response = await HttpService.get<Starter[]>(
      `${this.url}bysportevent/${sporteventId}/`
    );
    if (response !== undefined) return response;
    return [];
  }

  async getAllBySporteventCompetitionWithApi(
    sporteventCompetitionId: string
  ): Promise<Starter[]> {
    const response = await HttpService.get<Starter[]>(
      `${this.url}bysporteventcompetition/${sporteventCompetitionId}/`
    );
    if (response !== undefined) return response;
    return [];
  }

  async getAllBySporteventWithoutStartgroup(
    sporteventCompetitionId: string
  ): Promise<Starter[]> {
    const response = await HttpService.get<Starter[]>(
      `${this.url}bysporteventwithoutstartgroup/${sporteventCompetitionId}/`
    );
    if (response !== undefined) {
      const selectedIds = response.map(({ id }) => id);
      const allStarters = await this.getAll(false, true);
      return allStarters.filter((starter: Starter) =>
        selectedIds.includes(starter.id)
      );
    }

    return [];
  }
  async getAllBySporteventAndSocietyWithApi(
    sporteventId: string,
    societyId: string
  ): Promise<Starter[]> {
    const response = await HttpService.get<Starter[]>(
      `${this.url}bysporteventandsociety/${sporteventId}/${societyId}/`
    );
    if (response !== undefined) return response;
    return [];
  }

  async uploadCsv(sporteventId: string, formData: FormData) {
    // eslint-disable-next-line
    const response = await HttpService.post<Record<string, any>>(
      `${this.url}csv-import/${sporteventId}/`,
      formData,
      true
    );
    return response;
  }
}

export default new StarterService();
