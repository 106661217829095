import Competition from "../sporteventCalculation/Competition";
import Discipline from "../sporteventCalculation/Discipline";

export default class SporteventCompetition {
  id?: string;
  competition: Competition = new Competition();
  disciplines: Discipline[] = [];

  constructor(sporteventCompetition?: SporteventCompetition) {
    if (sporteventCompetition === undefined || sporteventCompetition === null) {
      return;
    }
    this.id = sporteventCompetition.id;
    this.competition = sporteventCompetition.competition;
    this.disciplines = sporteventCompetition.disciplines;
  }
}
