import TeamCalculation from "../sporteventCalculation/TeamCalculation";
import Sportevent from "./Sportevent";
import SporteventCompetition from "./SporteventCompetition";
import Team from "./Team";

export default class TeamCompetition {
  id?: string;
  sportevent: Sportevent = new Sportevent();
  teamCalculation: TeamCalculation = new TeamCalculation();
  sporteventCompetition: SporteventCompetition = new SporteventCompetition();
  teams: Team[] = [];

  constructor(teamCompetition?: TeamCompetition) {
    if (teamCompetition === undefined || teamCompetition === null) {
      return;
    }
    this.id = teamCompetition.id;
    this.sportevent = teamCompetition.sportevent;
    this.teamCalculation = teamCompetition.teamCalculation;
    this.sporteventCompetition = teamCompetition.sporteventCompetition;
  }

  getName(): string {
    return `${this.teamCalculation.name} ${this.sporteventCompetition.competition.name}`;
  }
}
