import Team from "@/models/sportevent/Team";
import TeamResult from "@/models/sportevent/TeamResult";
import BaseService from "../BaseService";
import HttpService from "../HttpService";

class TeamService extends BaseService<Team> {
  public url: string;

  constructor(url = "sportevent/team/") {
    super(url, "");
    this.url = url;
  }

  async getAllBySportevent(sporteventId: string) {
    const response = await HttpService.get<Team[]>(
      `${this.url}bysportevent/${sporteventId}/`
    );
    return response;
  }

  async getAllByTeamCompetitonId(teamCompetitonId: string) {
    const response = await HttpService.get<Team[]>(
      `${this.url}byteamcompetiton/${teamCompetitonId}/`
    );
    return response;
  }

  async getAllTeamsWithResults(sporteventId: string) {
    const response = await HttpService.get<TeamResult[]>(
      `${this.url}results/${sporteventId}/`
    );
    return response;
  }

  async saveList(teams: Team[]): Promise<Team[]> {
    const response = await HttpService.put<Team[]>(
      `${this.url}list_update/`,
      teams
    );
    if (response !== undefined) {
      response.map((i) => new Team(i));
      return response;
    }
    return [];
  }
}

export default new TeamService();
