export default class TeamCalculation {
  id?: string;
  name = "";
  minimumParticipants = 0;
  maximumParticipants = 0;
  maximumFemale = 0;
  maximumMale = 0;
  resultsForScoring = 0;

  constructor(teamCalculation?: TeamCalculation) {
    if (teamCalculation === undefined || teamCalculation === null) {
      return;
    }
    this.id = teamCalculation.id;
    this.name = teamCalculation.name;
    this.minimumParticipants = teamCalculation.minimumParticipants;
    this.maximumParticipants = teamCalculation.maximumParticipants;
    this.maximumFemale = teamCalculation.maximumFemale;
    this.maximumMale = teamCalculation.maximumMale;
    this.resultsForScoring = teamCalculation.resultsForScoring;
  }
}
