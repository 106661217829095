import AgeGenderGroup from "./AgeGenderGroup";

export default class Competition {
  id?: string;
  name = "";
  longName = "";
  ageGenderGroup: AgeGenderGroup = new AgeGenderGroup();
  oetbWienID? = null;
  disabled = false; // need vor vue-multiselect-listbox
  isTeamCompetition = false;

  constructor(competition?: Competition) {
    if (competition === undefined || competition === null) {
      return;
    }
    this.id = competition.id;
    this.name = competition.name;
    this.longName = competition.longName;
    this.ageGenderGroup = competition.ageGenderGroup;
    this.oetbWienID = competition.oetbWienID;
    this.isTeamCompetition = competition.isTeamCompetition;
    this.disabled = false; // need vor vue-multiselect-listbox
  }

  getExtendedName(): string {
    if (this.id != undefined) {
      return `${this.name} ∘ (${this.ageGenderGroup.gender} ${this.ageGenderGroup.ageFrom}-${this.ageGenderGroup.ageTo})`;
    } else {
      return "";
    }
  }
}
