export default class Person {
  id?: string;
  email?: string;
  firstname = "";
  lastname = "";
  dateOfBirth: Date = new Date();
  gender = "M";
  oetbWienID?: number;
  defaultSocietyId?: string;

  constructor(person?: Person) {
    if (person === undefined || person === null) {
      return;
    }
    this.id = person.id;
    this.email = person.email;
    this.firstname = person.firstname;
    this.lastname = person.lastname;
    this.dateOfBirth = person.dateOfBirth;
    this.gender = person.gender;
    this.oetbWienID = person.oetbWienID;
    this.defaultSocietyId = person.defaultSocietyId;
  }

  getFullname(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  getFullnameWithAge(): string {
    return `${this.firstname} ${this.lastname} (${this.getAge()})`;
  }

  getAge(): number {
    const today = new Date();
    const birthDate = new Date(this.dateOfBirth);
    const age = today.getFullYear() - birthDate.getFullYear();
    return age; // Darf nur JAHRGANG zurückgeben und nicht Jahr ;)
  }
}
